import { HomePage } from './home-page'

export class HomeResolver {
    constructor() {
        this.homePage = new HomePage()
    }

    initialize() {
        this.homePage.init()
    }

    get homeHandler() {
        return this.homePage
    }
}

window.currentResolver = new HomeResolver()

$j(document).ready(function () {
    window.currentResolver.initialize()
})
